.App {
    text-align: center;
    background-color: #000;
}
  
.App-logo {
    height: 10vmin;
    pointer-events: none;
}
  
  .App-header {
    padding: 20px;
    background-image: url("https://media.giphy.com/media/8YHKzQQMhyTL18zoSq/giphy.gif");
    min-height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #fff;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .Card-List {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    background: #fbee61;
    justify-content: center;
  }
  
  .Payment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px;
    background-color: #202124;
    padding: 10px;
  }
  
  .Price {
    margin-top: 20px;
  }
  
  .Plan-Card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 15px;
    min-height: 320px;
    background-color: #202124;
    padding: 10px;
  }
  
  .Yes-Icon {
    height: 15px;
    margin-right: 5px;
  }
  
  .Details {
    margin-top: 20px;
    text-align: start;
  }
  
  .Plan-Info {
    padding: 15px 10px;
  }
  
  .Plan-Actions {
    display: flex;
    flex-direction: column;
  }
  
  .App-Text {
    font-size: 16px;
    color: white;
    align-items: center;
    align-content: center;
    margin-bottom: 5px;
    width: 230px;
    word-wrap: break-word;
  }
  
  .Sub-Text {
    font-size: 16px;
    color: #ffffff;
    align-items: center;
    align-content: center;
    margin-bottom: 5px;
    margin-top: 20px;
    max-width: 350px;
    word-wrap: break-word;
  }
  
  .Title {
    font-weight: bold;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #fbee61;
    padding-bottom: 5px;
  }
  
  .User-Name {
    color: #fbee61;
    font-weight: bold;
  }
  
  input {
    width: 250px;
    margin-bottom: 10px;
    height: 32px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    border-color: rgb(203, 254, 252);
  }
  
  button {
    margin-top: 10px;
    width: 250px;
    height: 32px;
    background: #61dafb;
    font-style: italic;
    cursor: pointer;
    font-size: 16px;
    color: #000;
    font-weight: bold;
    border-color: rgb(203, 254, 252);
  }
  
  .mode-link {
    margin-top: 10px;
    font-size: 16px;
    color: #202124;
  }
  
  .mode-link:hover {
    color: #61dafb;
    cursor: pointer;
  }
  
  .separator-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .separator-line-before {
    height: 0.5px;
    width: 100px;
    margin-top: 5px;
    background: #61dafb;
    margin-right: 10px;
  }
  
  .separator-line-middle {
    color: #fff;
    font-size: 16px;
  }
  
  .separator-line-after {
    height: 0.5px;
    margin-top: 5px;
    margin-left: 10px;
    width: 100px;
    background: #61dafb;
  }